<style>
.bg-ico-hero {
  height: 100vh;
  background-image: url('~~~@/assets/images/crypto/bg-ico-hero.jpg');
}
</style>

<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import {HalfCircleSpinner} from "epic-spinners";
import {get_acsrf_token} from "@/methods";

export default {
  page: {
    title: "Authorize an application",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      application_id: null,
      ready: false,
      error: false,
      resources: [],
      resource_map: {},
      application: null,
      inProgress: false
    };
  },
  components: {HalfCircleSpinner},
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.ready = true;
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v2/api/${this.application_id}/resources`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.resources = data.resources;
          this.resources.forEach((resource) => {
            this.resource_map[resource.id] = resource.type;
          });
          this.application = data.application;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async grantTokens() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      try {
        let resources = [];

        Object.keys(this.$refs).forEach(el => {
          let ref = this.$refs[el][0];
          if(!ref) return;
          let resource_id = ref.value;
          if(ref.isChecked) resources.push({id: resource_id, type: this.resource_map[resource_id]});
        })

        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          resources: resources
        };

        let response = await fetch(process.env.VUE_APP_ROOT_API + `v2/api/${this.application_id}/tokens`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.$swal({
            icon: 'success',
            text: this.$t('api.authorize.success')
          });
          this.getData();
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    }
  },
  created() {
    this.application_id = this.$route.params.application_id;
  },
  mounted() {
    this.getData();
  },
};
</script>

<template>
  <div>
    <div class="section hero-section bg-ico-hero" id="home">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-12">
            <div class="card">
              <div class="card-header">
                <h3 class="text-white font-weight-semibold p-0">
                  {{ $t('api.authorize.title') }}
                </h3>
              </div>
              <div class="card-body">
                <template v-if="ready">
                  <template v-if="error">
                    <div class="row">
                      <div class="col-lg-12 justify-content-center">
                        <div  class="info-component text-center align-middle mt-auto mb-auto">
                          <div class="info-component-space">
                            <i class="fal fa-times text-danger info-component-icon"></i>
                          </div>
                          <div class="row" style="margin-top: 20px;">
                            <div class="col-lg-12">
                              <h5 class="text-muted">{{$t('error.server.generic.message')}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row justify-content-center">
                      <div class="col text-center">
                        <h4 class="pb-0">
                          {{ application.name }} <span class="badge badge-primary font-size-12">Integration</span>
                        </h4>
                        <h6 class="p-0 text-muted">
                          {{ application.id }}
                        </h6>
                      </div>
                    </div>
                    <hr>
                    <template v-if="resources.length === 0">
                      <div class="row text-center">
                        <div class="col-12">
                          <h3>
                            {{ $t('api.authorize.none') }}
                          </h3>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="row justify-content-center">
                        <div class="col-8">
                          <h6>
                            {{ $t('api.authorize.prompt') }}
                          </h6>
                        </div>
                      </div>
                      <div class="row justify-content-center" v-for="resource in resources" :key="resource.id">
                        <div class="col-8">
                          <b-form-checkbox
                              :ref="resource.id"
                              :value="resource.id"
                              unchecked-value=""
                          >
                            {{ resource.identifier }}
                            <template v-if="resource.type === 'server'">
                              <span class="badge badge-primary">
                                <i class="fas fa-server" />
                                SERVER
                              </span>
                            </template>
                            <template v-else-if="resource.type === 'banlist'">
                              <span class="badge badge-info">
                                <i class="far fa-ban" />
                                BANLIST
                              </span>
                            </template>
                          </b-form-checkbox>
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col text-center">
                          <div class="alert alert-warning text-black mt-2">
                            {{ $t('api.authorize.warning') }}
                          </div>

                          <button class="btn btn-primary" v-on:click="grantTokens()" :disabled="inProgress">
                            <template v-if="inProgress">
                              <half-circle-spinner
                                  :animation-duration="1200"
                                  :size="16"
                                  class="align-middle d-inline-block text-danger"
                              />
                            </template>
                            <template v-else>
                              {{ $t('api.authorize.button') }}
                            </template>
                          </button>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-lg-12 justify-content-center">
                      <div  class="info-component text-center align-middle mt-auto mb-auto">
                        <div class="info-component-space">
                          <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-6">{{ new Date().getFullYear() }} © CFTools</div>
                  <div class="col-sm-6">
                    <div class="text-sm-right d-none d-sm-block">
                      <small style="font-size: 10px;">
                        <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
                        /
                        <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>